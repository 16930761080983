import React, { PropsWithChildren } from "react"

import MasonryGrid from "../LayoutComponents/MasonryGrid"
import { Color } from "../../../constants/V2/color"
import GridSectionTitles, {
  GridSectionTitleProps,
} from "../../elements/V2/GridSectionTitles"
import LayoutSection from "../LayoutComponents/LayoutSection"

import { getAccentColor } from "@utils/V2/color"

type Props = PropsWithChildren<
  GridSectionTitleProps & {
    id?: string
    headingSize?: "small" | "large"
    alignment?: "center" | "left"
    overrideSuperscriptColor?: Color
    overrideHeadingTextColor?: Color
    overrideHeadingBodyColor?: Color
    primaryBackgroundColor: Color
    secondaryBackgroundColor?: Color
  }
>

const MasonryGridSection = ({
  id,
  headingSize = "large",
  alignment = "center",
  superscriptText,
  overrideSuperscriptColor,
  headingText,
  overrideHeadingTextColor,
  headingBodyText,
  overrideHeadingBodyColor,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  children,
  ...props
}: Props) => {
  return (
    <LayoutSection
      id={id}
      primaryBackgroundColor={primaryBackgroundColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      {...props}
    >
      {(superscriptText || headingText || headingBodyText) && (
        <GridSectionTitles
          size={headingSize}
          alignment={alignment}
          superscriptText={superscriptText}
          superscriptColor={
            overrideSuperscriptColor || getAccentColor(primaryBackgroundColor)
          }
          headingText={headingText}
          headingTextColor={
            overrideHeadingTextColor || getAccentColor(primaryBackgroundColor)
          }
          headingBodyText={headingBodyText}
          headingBodyColor={
            overrideHeadingBodyColor || getAccentColor(primaryBackgroundColor)
          }
        />
      )}

      <MasonryGrid>{children}</MasonryGrid>
    </LayoutSection>
  )
}

export default MasonryGridSection
