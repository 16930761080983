import classNames from "classnames"
import React, { PropsWithChildren } from "react"

import Masonry from "../../Masonry"

/*
  This Masonry implementation uses a columnsPerBreakpoint config in order to determine how many flex div columns
  are rendered. The width of each column is equal (width 100% if there is only 1 column). Each column's height is then tracked
  and re-calculated after each child element passed into the Masonry component is auto-placed (into the column with thelowest height).
*/

type Props = {
  className?: string
}

const MasonryGrid = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <Masonry
      className={classNames(
        "w-auto flex items-start gap-20-v2 md-v2:gap-24-v2 lg-v2:gap-32-v2 xl-v2:gap-40-v2",
        className
      )}
      columnClassName="flex flex-col gap-20-v2 md-v2:gap-24-v2 lg-v2:gap-32-v2 xl-v2:gap-40-v2"
      multiColumnClassName="last:mt-40-v2 md-v2:last:mt-48-v2 lg-v2:last:mt-64-v2 xl-v2:last:mt-80-v2"
    >
      {children}
    </Masonry>
  )
}

export default MasonryGrid
